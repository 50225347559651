import React, { Component } from 'react';
import Select from 'react-select';
import { Query, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import UserData from '../components/UserData';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { GET_REGIONS } from '../constants/globalQueries';
import { setRegion } from '../actions/setRegion';
import { FaUser } from 'react-icons/fa';
import { NOMINATION } from '../constants/roles';
import { logOutUser } from '../actions/loginUser';
import { get } from 'lodash';
import { GET_NOMINATION_BY_ID } from '../containers/UserProfile/queries-mutations';
import { isEmpty } from '../constants/helper';

const HeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  padding: 10px 30px;
  background-color: #fff;

  @media (min-width: 992px) {
    background-color: #177dff;
  }
`;
const ToggleBtn = styled.div`
  position: relative;
  width: 40px;
  height: 40px;

  cursor: pointer;

  span,
  span::before,
  span::after {
    width: 20px;
    height: 1px;
    background-color: #545454;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }
  }

  @media (min-width: 992px) {
    background-color: rgba(255, 255, 255, 0.22);
    border-radius: 50%;

    span,
    span::before,
    span::after {
      width: ${(props) => (props.sideBarState ? '20px' : '2px')};
      background-color: #fff;
      transition: width 0.3s ease;
    }
  }
`;
const AvatarContainer = styled.div`
  position: relative;
  margin-left: 30px;
`;
const DropdownWrapper = styled.div`
  .Dropdown-root {
    cursor: pointer;
    width: 135px;
    font-size: 14px;
    @media (min-width: 768px) {
      width: 360px;
      font-size: 16px;
    }
  }

  .headerDropdown__control {
    background-color: rgb(74, 154, 255);
    cursor: pointer;
    border-width: 0px;
  }
  .headerDropdown__single-value {
    color: #ffffff;
  }

  .headerDropdown__indicator {
    color: #ffffff;
  }

  .headerDropdown__indicator-separator {
    display: none;
  }

  .headerDropdown__input {
    color: white;
  }
`;
const DropdownAvatarContainer = styled.div`
  display: flex;
`;
const UserAvatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;

  img {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
    width: 100%;
  }

  .pg-viewer-wrapper {
    overflow: visible;
  }
`;
const token = 'AuthToken';

class Header extends Component {
  state = {
    isOpened: false
  };

  togglePopUp = (state) => {
    this.setState({ isOpened: state });
  };

  onSelect = (data, regionsList) => {
    const { setRegion, history } = this.props;
    const reportingPage = /reporting/.test(window.location.pathname);
    const selectedRegion = regionsList.find((region) => region.id === data.value);

    setRegion({
      id: data.value,
      name: data.label,
      isGlobalRegion: selectedRegion.isGlobalRegion
    });

    /**
     * If we are on nomination page and we changed region we should redirect user (ADMIN?, ADVISOR, COMPETITION LEAD)
     * to root dashboard.
     */
    const isNominationPage = /nomination-page/.test(window.location.pathname);
    if (isNominationPage) {
      document.location.href = '/';
    }

    reportingPage && history.replace('/reporting');
  };

  logOutUser = () => {
    const { history } = this.props;
    this.props.logOutUser();
    history.replace('/');
  };

  rename = (obj) => {
    if (obj) {
      return {
        value: obj.id,
        label: obj.name
      };
    } else {
      return {
        value: null,
        label: 'Select region'
      };
    }
  };

  render() {

    const { isOpened } = this.state;
    const { toggleSideBar, sideBarState, user, noRegions, fbLogin, removeVote } = this.props;

    const isNominee = user.role === NOMINATION;
    const prefixLink = process.env.REACT_APP_UPLOADED_FILES_LINK;

    return (
      <HeaderStyle>
        <div>
          <ToggleBtn sideBarState={sideBarState} onClick={toggleSideBar}>
            <span />
          </ToggleBtn>
        </div>
        <DropdownAvatarContainer>
          {((user.role !== NOMINATION) && !noRegions) && (
            <Query query={GET_REGIONS} fetchPolicy="network-only">
              {({ client, loading, data, error }) => {
                if (loading) return <div>Loading</div>;
                if (error) return <div>{error.message}</div>;

                const regionsList = get(data, 'getRegions', []).items;
                const regionNames =
                  data &&
                  data.getRegions &&
                  data.getRegions.items &&
                  data.getRegions.items.map((item) => this.rename(item));

                return (
                  <DropdownWrapper>
                    {!loading &&
                    data &&
                    data.getRegions &&
                    data.getRegions.items &&
                    data.getRegions.items.length > 0 ? (
                      <Select
                        className="Dropdown-root"
                        classNamePrefix="headerDropdown"
                        isSearchable={true}
                        options={regionNames}
                        onChange={(data) => {
                          this.onSelect(data, regionsList);
                        }}
                        value={this.rename(user && user.region && user.region)}
                        placeholder="Select region"
                      />
                    ) : (
                      'No regions '
                    )}
                  </DropdownWrapper>
                );
              }}
            </Query>
          )}
          <AvatarContainer>
            {isNominee ? (
              <Query
                query={GET_NOMINATION_BY_ID}
                variables={{ id: user.id }}
                fetchPolicy="network-only"
              >
                {({ data, loading, error }) => {
                  if (loading && (!data || isEmpty(data))) return <div>Loading</div>;
                  if (error) return <div>{error.message}</div>;
                  const nomineeData = get(data, 'getNominationById', null);

                  return (
                    <UserAvatar onClick={() => this.togglePopUp(true)}>
                      {nomineeData?.uploadedFiles?.profilePicture?.title ? (
                        <img
                          src={`${prefixLink}?file=${nomineeData.uploadedFiles.profilePicture.title}&token=${token}`}
                          alt="Avatar"
                        />
                      ) : (
                        <FaUser size={36} />
                      )}
                    </UserAvatar>
                  );
                }}
              </Query>
            ) : (
              <UserAvatar onClick={() => this.togglePopUp(true)}>
                {user.image ? <img src={user.image} alt="Avatar" /> : <FaUser size={36} />}
              </UserAvatar>
            )}

            <UserData
              user={user}
              togglePopUp={this.togglePopUp}
              isOpened={isOpened}
              logOutUser={this.logOutUser}
              fbLogin={fbLogin}
              removeVote={removeVote}
            />
          </AvatarContainer>
        </DropdownAvatarContainer>
      </HeaderStyle>
    );
  }
}

export function mapStateToProps({ user }) {
  return {
    user
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logOutUser,
      setRegion
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Header);
